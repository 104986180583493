import { render, staticRenderFns } from "./xingye.vue?vue&type=template&id=08f5f8fd&scoped=true&"
import script from "./xingye.vue?vue&type=script&lang=js&"
export * from "./xingye.vue?vue&type=script&lang=js&"
import style0 from "./xingye.vue?vue&type=style&index=0&id=08f5f8fd&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08f5f8fd",
  null
  
)

export default component.exports